/**
 * Generates a JS timestamp date for the midnight of the current day, local time.
 * @returns a JS timestamp
 */
export const lastDayTime = () => {
  const t = new Date();
  t.setHours(0);
  t.setMinutes(0);
  t.setSeconds(0);
  t.setMilliseconds(0);
  return t.valueOf();
};

/**
 * Generates a JS timestamp date for the next midnight after now, local time.
 * @returns a JS timestamp
 */
export const nextDayTime = () => {
  const t = new Date();
  t.setHours(0);
  t.setMinutes(0);
  t.setSeconds(0);
  t.setMilliseconds(0);
  t.setDate(t.getDate() + 1);
  return t.valueOf();
};
