/**
 * @module redux/update
 * @description
 * This module provides a function that implements the background
 * update logic for the redux store. It should be called periodically.
 *
 * @todo
 * We possibly should be smart enough to include a last update tag,
 * or something equivalent, so that even though we are likely to be
 * polling, we don't have to even consider a redux store change
 * unless the server knows that something has changed.
 */

import { update as updateData } from '../redux/devices-slice';

export default function update(store) {
  const state = store.getState();
  const filter = state.devices.filter;
  store.dispatch(updateData(filter));
}
