/**
 * @module Router
 * @description
 * The main router component for the web application. This is where the
 * "pages" in the web application are set up.
 */

import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";

import DevicesPage from './DevicesPage';
import DeviceMap from './DeviceMap';

export default function RootRouter() {
  return (
    <Switch>
      <Route path="/devices" component={ DevicesPage } />
      <Route path="/" component={ DeviceMap } />
    </Switch>
  );
}
