/**
 * @module redux/devices-slice
 * @description
 * This module provides the redux store slice for the devices,
 * including the currently selected device, and its history.
 * This should periodically refresh and update the data in
 * the store.
 */

import {
  createSlice,
  createAsyncThunk,
  createSelector
} from '@reduxjs/toolkit';
import 'whatwg-fetch';

import { lastDayTime, nextDayTime } from '../util/time-utilities';

const initialState = {
  filter: {
    device: null,
    start: lastDayTime(),
    end: nextDayTime()
  },
  loaded: false,
  data: []
};

/**
 * Creates the async thunk that packages a history request. This should also
 * smartly handle the defaulting for the time range. If the arg includes a
 * start and end, they should be passed to the server. But if not, we make one,
 * which is the range between midnight tonight and midnight last night, i.e.,
 * we default to a generous version of "today".
 *
 * The day itself isn't stored anywhere obvious, but should be returned as
 * part of the server response, so we get it back anyway, in a form so that
 * we can then display it later.
 */
export const update = createAsyncThunk('devices/update', async (filter, thunkApi) => {    // eslint-disable-line no-unused-vars
  const { ...copy } = filter;
  if (copy.device === null) {
    delete copy.device;
  }
  const params = new URLSearchParams(copy);

  const response = await fetch(`/api/devices?${params}`);
  const data = await response.json();
  return {
    filter: data.filter,
    data: data.devices,
    loaded: false
  };
});

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    update: (state, action) => ({...state, loaded: false, filter: action.payload.filter})
  },
  extraReducers: (builder) => {
    builder
      .addCase(update.fulfilled, (state, action) => {
        state.filter = action.payload.filter;
        state.data = action.payload.data;
        state.loaded = action.payload.loaded;
      });
  }
});

export default devicesSlice.reducer;

export const selectFilter = (state) => state.devices.filter;
export const selectDeviceData = (state) => state.devices.data;

export const selectFilterDevice = createSelector(
  [selectFilter],
  (filter) => filter.device
);
