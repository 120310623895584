/**
 * @module Navigation
 * @description
 * Provides the main navigation system for the web application. The
 * application is using Bootstrap, so most of what is happening here
 * is a Bootstrap navigation bar, with a little redux magic to pull
 * an authenticated user out into menu state.
 */

import React, { useRef, useEffect } from "react";
import {
  NavLink,
  useHistory
} from "react-router-dom";

import Collapse from 'bootstrap.native/src/components-v4/collapse-native';

/**
* Renders a fragment that includes a navigation bar and the main
* @returns the Navigation component
*/
export default function Navigation() {

  let history = useHistory();
  const collapseElement = useRef(null);

  useEffect(() => {
    const collapse = new Collapse(collapseElement.current);
    const unlisten = history.listen(() => {
      collapse.hide();
    });
    return unlisten;
  }, []);

  return (
    <div className="navbar fixed-top navbar-expand-md navbar-dark bg-dark">
      <div className="container">
        <a className="navbar-brand mb-0 h1" href="/">AmbieSense</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" ref={collapseElement}
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/" activeClassName="active">Map</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/devices" activeClassName="active">Devices</NavLink>
            </li>
            <li className="nav-item dropdown">
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/settings">Settings</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
