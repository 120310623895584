/**
 * @module ShowDevices
 * @description
 * The react component that implements the main map view with markers
 * for each device.
 */

import React, { Fragment } from "react";
import PropTypes from 'prop-types';         // eslint-disable-line no-unused-vars

import { Marker, Tooltip } from "react-leaflet";
import { useSelector, useDispatch } from 'react-redux';

import { update, selectDeviceData, selectFilter } from '../redux/devices-slice';

import { getDeviceIcon } from './support/device-icon';

import DeviceHistory from './DeviceHistory';

const getDeviceTooltip = (device) => {
  const battery = (device.battery_level) ? <Fragment><br />Battery level: { device.battery_level }</Fragment> : null;
  return <Tooltip direction="left">Last updated: { (new Date(device.lastUpdated)).toLocaleString() }{ battery }</Tooltip>;
};

export default function ShowDevices() {

  const dispatch = useDispatch();
  const devices = useSelector(selectDeviceData);
  const filter = useSelector(selectFilter);

  function selectDevice(id) {
    const { start, end } = filter;
    const updatedFilter = { device: id, start, end };
    dispatch(update(updatedFilter));
  }

  if (devices === null || devices.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {devices.map((c) => (
        <Fragment key={c.id}>
          <Marker
            key={c.id}
            dataKey={c.id}
            position={[
              c.device.latitude,
              c.device.longitude
            ]}
            eventHandlers={{
              click: () => selectDevice(c.id)
            }}
            icon={ getDeviceIcon(c.device) }
          >
            { getDeviceTooltip(c.device) }
          </Marker>
          <DeviceHistory id={c.id} device={c.device} history={c.history}></DeviceHistory>
        </Fragment>
      ))}
    </Fragment>
  );
}

ShowDevices.propTypes = {};
