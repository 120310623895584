import { divIcon } from "leaflet";

export const getDeviceIcon = (device) => {
  const iconClass = device.type || 'truck-moving';
  const iconLabel = (device.label || device.id).replace(/[\u00A0-\u9999<>&]/g, function(i) {
    return '&#'+i.charCodeAt(0)+';';
  });

  return new divIcon({
    className: `as-icon fas fa-${iconClass}`,
    html: `<span class='as-icon-label'>${iconLabel}</span>`
  });
};
