/**
 * @module redux/store
 * @description
 * This module provides the main redux store.
 */

import { configureStore } from '@reduxjs/toolkit';
import devicesReducer from './devices-slice';
import locationReducer from './location-slice';

/**
 * Export the configured redux store.
 */
export default configureStore({
  reducer: {
    devices: devicesReducer,
    location: locationReducer
  }
});
