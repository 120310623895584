/**
 * @module DevicesList
 * @description
 * A router-managed component that shows devices on a list. We can
 * share the filter and redux storage with the map, but maybe that is
 * not an especially good plan.
 */

import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Link,
} from "react-router-dom";

import { update, selectDeviceData, selectFilter } from '../redux/devices-slice';

function sortByTime(a, b) {
  if ( a.device.time < b.device.time ){
    return -1;
  }
  if ( a.device.time > b.device.time ){
    return 1;
  }
  return 0;
}

export default function DevicesList() {

  const dispatch = useDispatch();

  const filter = useSelector(selectFilter);
  const devices = useSelector(selectDeviceData).slice().sort((a, b) => -sortByTime(a, b));

  let deviceLabel = null;
  if (filter.device && devices.length === 1) {
    const deviceData = devices[0].device;
    deviceLabel = deviceData.label || deviceData.id;
  }
  console.log(filter);

  function clearDeviceFilter(e) {
    e.preventDefault();
    e.stopPropagation();
    const { start, end } = filter;
    const updatedFilter = { device: null, start, end };
    dispatch(update(updatedFilter));
  }

  return (
    <Fragment>
      <h2 className="ambie-breadcrumb">
        <div className="ambie-breadcrumb-item">
          {
            (deviceLabel) ?
              <a href="" onClick={ clearDeviceFilter }>All devices</a> :
              "All devices"
          }
        </div>
        {
          (deviceLabel) ?
            (<div className="ambie-breadcrumb-item active" aria-current="page">{ deviceLabel }</div>) :
            null
        }
      </h2>
      <table className="table table-sm table-responsive">
        <thead>
          <tr className="d-flex">
            <th scope="col" className="col-4">Identifier</th>
            <th scope="col" className="col-4">Updated</th>
            <th scope="col" className="col-4">Location</th>
          </tr>
        </thead>
        <tbody>
          {
            devices.map((d) => <tr key={d.id} className="d-flex">
              <td className="col-4 text-truncate">
                <Link to={ "/devices/" + encodeURIComponent(d.id) } >
                  { d.id }
                </Link>
              </td>
              <td className="col-4 text-truncate">{ new Date(d.device.time).toLocaleString('short') }</td>
              <td className="col-4 text-truncate">{ (d.device.latitude).toFixed(5) },{ (d.device.longitude).toFixed(5) }</td>
            </tr>)
          }
        </tbody>
      </table>
    </Fragment>
  );
}
