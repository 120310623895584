/**
 * @module DeviceControl
 *
 * @description
 * Implements a leafletjs "control", that is, a floating panel over the map
 * that shows status information and allows people to interact with the
 * information that is displayed. Some of this is very carefully planned to
 * integrate with leafletjs and even CSS, so it is not completely trivial to change
 * positioning, and so on.
 *
 * The component uses redux to draw on the `selectedDevice` from the devices
 * slice, as the source of the data displayed in the control.
 */

import React, { Fragment } from 'react';    // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';         // eslint-disable-line no-unused-vars

import { useSelector, useDispatch } from 'react-redux';
import { update, selectFilter, selectDeviceData } from '../redux/devices-slice';
import classNames from 'classnames';

/**
 * A mapping between intuitive positions, and the classes used by
 * leafletjs's CSS classes.
 */
const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

/**
 * Triggers a date navigation, with the current start date adjusted
 * by a day, one way or another.
 * @param {*} startDate
 * @param {*} increment
 */
const handleDayChange = (dispatch, filter, startDate, increment) => {
  const actualDate = new Date(startDate);
  const currentDate = Date.now();

  // Step 1, build a new date object
  actualDate.setHours(0);
  actualDate.setMinutes(0);
  actualDate.setSeconds(0);
  actualDate.setMilliseconds(0);
  actualDate.setDate(actualDate.getDate() + increment);
  const newStart = actualDate.valueOf();

  // Step 2, build a new end date
  actualDate.setDate(actualDate.getDate() + 1);
  const newEnd = actualDate.valueOf();

  if (newStart > currentDate) {
    return;
  }

  // Step 3, dispatch a new action
  const updatedFilter = {device: filter.device, start: newStart, end: newEnd};
  dispatch(update(updatedFilter));
};

/**
 * Export the DeviceControl as a react component.
 */
export function DeviceControl({ position }) {
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  const dispatch = useDispatch();

  const filter = useSelector(selectFilter);
  const devices = useSelector(selectDeviceData);

  const enableBackArrow = true;
  const enableForwardArrow = true;
  const enableToday = true;
  const startDate = filter.start;

  const offset = (new Date()).getTimezoneOffset();
  const offsetHours = Math.floor(offset / 60).toFixed(2);

  // If we have a device filter, find the label
  let deviceLabel = null;
  if (filter.device && devices.length === 1) {
    const deviceData = devices[0].device;
    deviceLabel = deviceData.label || deviceData.id;
  }

  function nextDay(e) {
    e.stopPropagation();
    if (! e.target.disabled) {
      handleDayChange(dispatch, filter, startDate, 1);
    }
  }

  function previousDay(e) {
    e.stopPropagation();
    if (! e.target.disabled) {
      handleDayChange(dispatch, filter, startDate, -1);
    }
  }

  function clearDay(e) {
    e.preventDefault();
    e.stopPropagation();
    if (! e.target.disabled) {
      handleDayChange(dispatch, filter, new Date(), 0);
    }
  }

  function clearDeviceFilter(e) {
    e.preventDefault();
    e.stopPropagation();
    const { start, end } = filter;
    const updatedFilter = { device: null, start, end };
    dispatch(update(updatedFilter));
  }

  function ignoreEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-device" onDoubleClickCapture={ignoreEvent} >
        <div className="ambie-device-row">
          { (new Date(startDate)).toLocaleDateString(void 0, {weekday: 'long', day: 'numeric', month: 'short', year: 'numeric'}) } UTC { offsetHours >= 0 ? "+" : null }{ offsetHours }
        </div>
        <div className="ambie-device-row">
          <button onClick={previousDay} disabled={!enableBackArrow} className={ classNames("as-command-button", {'disabled': !enableBackArrow}, 'fas', 'fa-chevron-left') }></button>
          <button onClick={clearDay} disabled={!enableToday} className={ classNames("as-command-button", {'disabled': !enableToday}, 'fas', 'fa-calendar') }></button>
          <button onClick={nextDay} disabled={!enableForwardArrow} className={ classNames("as-command-button", {'disabled': !enableForwardArrow}, 'fas', 'fa-chevron-right') }></button>
        </div>
        <nav aria-label="breadcrumb" className="ambie-device-row">
          <div className="ambie-breadcrumb">
            <div className="ambie-breadcrumb-item preserve">
              {
                (deviceLabel) ?
                  <a href="" onClick={ clearDeviceFilter }>All devices</a> :
                  "All devices"
              }
            </div>
            {
              (deviceLabel) ?
                (<div className="ambie-breadcrumb-item active" aria-current="page">{ deviceLabel }</div>) :
                null
            }
          </div>
        </nav>
      </div>
    </div>
  );
}

DeviceControl.propTypes = {
  position: PropTypes.string,
};
