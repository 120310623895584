/**
 * @module DeviceMap
 * @description
 * A router-managed component that shows devices on a map.
 */

import React from 'react';

import Map from './Map';
import Location from './Location';
import ShowDevices from './ShowDevices';

export default function DeviceMap() {

  return (
    <Map attributionControl={ false }>
      <Location>
        <ShowDevices />
      </Location>
    </Map>
  );
}
