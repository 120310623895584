/**
 * @module DeviceHistory
 * @description
 * The react component that implements the device history display.
 */

import React, { Fragment } from "react";
import PropTypes from 'prop-types';   // eslint-disable-line no-unused-vars
import { Polyline, CircleMarker, LayerGroup, Tooltip } from "react-leaflet";

export default function DeviceHistory({id, device, history}) {     // eslint-disable-line no-unused-vars

  if (! device || ! history) {
    return <Fragment></Fragment>;
  }

  const length = history.length;

  const getHistoryRecordTooltip = (history) => {
    const battery = (history.battery_level) ? <Fragment><br />Battery level: { history.battery_level }</Fragment> : null;
    return <Tooltip>{ (new Date(history.arrivalTime || history.time)).toLocaleString() }{ battery }</Tooltip>;
  };

  return <LayerGroup>
    {
      (length < 2) ? null :
        <Polyline smoothFactor={0} weight={5} positions={ history.map((c) => [
          c.latitude, c.longitude
        ]) } />
    }
    {
      (history < 3) ? null :
        history.slice(1, length - 2).reverse().map((c, i) => (
          <CircleMarker key={ i } center={ [
            c.latitude, c.longitude
          ] } weight={ 2 } radius={ 8 } color={ '#fff' } fillOpacity={ 1.0 } fillColor={ '#3388FF' }
          className= { 'ambie-history-step' }>
            { getHistoryRecordTooltip(c) }
          </CircleMarker>
        ))
    }
    {
      (history < 2) ? null :
        <CircleMarker center={[
          history[length - 1].latitude,
          history[length - 1].longitude
        ]} weight={ 4 } radius={ 7 } color={ '#2266CC' } fillOpacity={ 0.0 } fillColor={ '#fff' }>
          { getHistoryRecordTooltip(history[length - 1]) }
        </CircleMarker>
    }
  </LayerGroup>;
}

DeviceHistory.propTypes = {
  id: PropTypes.string,
  history: PropTypes.array,
  device: PropTypes.object
};
