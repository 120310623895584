import React, { Fragment } from "react";
import PropTypes from 'prop-types';         // eslint-disable-line no-unused-vars

import { useSelector } from 'react-redux';
import { useMap } from "react-leaflet";

import { selectCoordinates } from '../../src/redux/location-slice';


/**
 * The location component can be nested inside the Map, and
 * contain a number of child components, but doesn't really
 * do much for them. It listens for redux context state changes
 * and when necessary passes them onto the leaflet state.
 *
 * Note that this should be fairly heavily throttled, and should
 * not be updating the map location very often. And even when it
 * does, we might want to consider not showing too much, maybe
 * moving a marker rather than re-centering the map all the time.
 */

export default function Location({children}) {

  // Update the map view to centre, but only if the current bounds
  // don't contain it. That way, we are minimally changing the view.
  const coordinates = useSelector(selectCoordinates);
  const map = useMap();
  if (map) {
    const bounds = map.getBounds();
    const position = {lat: coordinates.latitude, lng: coordinates.longitude};
    if (! bounds.contains(position)) {
      const zoom = map.getZoom();
      map.setView(position, zoom);
    }
  }

  return (
    <Fragment>
      { children }
    </Fragment>
  );
}

Location.propTypes = {
  children: PropTypes.node
};
