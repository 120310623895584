/**
 * @module DevicesPage
 * @description
 * A router-managed component that shows devices on a list page.
 */

import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import DevicesList from './DevicesList';

export default function DevicesPage() {
  let { path } = useRouteMatch();

  return (
    <div className="container ambiesense-page-container">
      <Switch>
        <Route path={path} component={DevicesList} />
      </Switch>
    </div>
  );
}
