/**
 * @module redux/location-slice
 * @description
 * This module provides the redux store slice for the location,
 * which is generally loaded only when the react application
 * starts up.
 */

import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit';

const initialState = {
  coordinates: {latitude: 41.0, longitude: 28.2, timestamp: null},
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCoordinates: (state, action) => ({...state, coordinates: action.payload})
  },
});

export default locationSlice.reducer;

export const selectCoordinates = createSelector(
  [(state) => state.location],
  (location) => {
    return location.coordinates;
  }
);

export const setCoordinates = locationSlice.actions.setCoordinates;
