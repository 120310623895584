/**
 * @module index
 * @description
 * The main buid point for the react web client component.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Container from './Container';
import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import store from '../redux/store';
import update from '../redux/update';

// Initial update
update(store);

/**
 * The update interval for the front end. This determines how
 * frequently the front end checks in with the server to see if
 * anything has moved.
 */
const UPDATE_INTERVAL = 30000;

setInterval(update, UPDATE_INTERVAL, store);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <Container />
  </Provider>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
