import React from "react";
import PropTypes from 'prop-types';         // eslint-disable-line no-unused-vars

import { MapContainer, TileLayer } from "react-leaflet";
import { useSelector } from 'react-redux';

import { DeviceControl } from './DeviceControl';
import { selectCoordinates } from '../redux/location-slice';

/**
 * Generates a map container with appropriate configurations.
 * @param {*} props
 * @param {Array} props.children - the child components
 * @returns a component
 */
export default function Map({children}) {

  // Note that this has no effect for updates, but it'll work on initialization,
  // which is a good start. That's because the MapContainer props are immutable. See:
  // https://react-leaflet.js.org/docs/api-map.
  //
  // To listen for updates, we also need to track changes and notify direct into the
  // Leaflet parts of the API. However, we can't do that here, as we don't have a
  // map yet.
  const location = useSelector(selectCoordinates);

  return (
    <MapContainer center={[location.latitude, location.longitude]} zoom={7}>
      <DeviceControl />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      {children}
    </MapContainer>
  );
}

Map.propTypes = {
  children: PropTypes.node
};
